@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */

    font-family: Lato;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-height: auto;
  /* background-color: black; */
  color: rgba(52, 60, 106, 1);
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* .slick-slide {
  margin: 0 10px;
} */
/* .slick-track{
  height: 400px;
} */

.slick-arrow.slick-prev {
  width: 50px;
  height: 50px;
  background: #3a6afd;
  border-radius: 15px;
  z-index: 1;
}

.slick-arrow.slick-next {
  width: 50px;
  height: 50px;
  background: #3a6afd;
  border-radius: 15px;
  z-index: 1;
  color: white;
}
.slick-arrow.slick-prev:hover,
.slick-arrow.slick-next:hover,
.slick-arrow.slick-prev:focus,
.slick-arrow.slick-next:focus,
.slick-arrow.slick-prev:active,
.slick-arrow.slick-next:active {
  background: #3a6afd; /* Ensure background color remains on hover, focus, and active states */
}
.slick-prev:before, .slick-next:before {
  font-size: 30px;
  /* color: white; */
}